<template>
  <div class="tc-login">
    <div class="login move">
      <el-form :model="ruleForm" class="demo-ruleForm" :rules="rules">
        <el-row>
          <p class="title-cn">谦雅心理CT</p>
          <!-- <p class="title-eu">USER LOGIN</p> -->
        </el-row>
        <el-form-item prop="username">
          <el-input
            prefix-icon="el-icon-user"
            v-model="ruleForm.username"
            placeholder="用户名"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            prefix-icon="el-icon-lock"
            type="password"
            v-model="ruleForm.password"
            placeholder="密码"
            @keyup.enter.native="userLogin"
          ></el-input>
        </el-form-item>
        <el-row class="adminOrUser" @keyup.enter.native="userLogin">
          <el-col :span="21">
            <el-radio v-model="ruleForm.type" :label="2">管理员</el-radio>
            <el-radio v-model="ruleForm.type" :label="1">普通用户</el-radio>
          </el-col>
          <el-col :span="3">
            <i @click="showQr" class="twologin">
              <img src="../../assets/images/login/rqcode.png" alt />
            </i>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="10" class="twologin">
            <i>
              <img src="../../assets/images/login/rqcode.png" alt />
            </i>
            <span @click="showQr">二维码登录</span>
          </el-col>
          <el-col :span="9" class="wpass">
            <span>忘记密码?</span>
          </el-col>
        </el-row>-->
        <el-row class="zhuche">
          <p>
            没有账号？
            <span @click="goRegistration">
              <a href="javascript:;">立即注册</a>
            </span>
          </p>
        </el-row>
        <el-form-item>
          <el-button type="primary" @click="userLogin" :disabled="isLogin"
            ><span v-if="!isLogin">登 录</span>
            <span v-if="isLogin"
              ><i class="el-icon-loading"></i>正在登陆</span
            ></el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      title="二维码登陆"
      :visible.sync="qrBox"
      width="20%"
      center
      :destroy-on-close="true"
    >
      <div class="qr">
        <div id="qrcode" ref="qrcode"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="qrBox = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { login, getadmininfo, bind_websocket_id } from "@/api/login.js";
import { getListConfig } from "@/api/comm.js";
import QRCode from "qrcodejs2";
let xxx = 123;
export default {
  data() {
    return {
      isRegister: true,
      ruleForm: {
        username: "",
        password: "",
        type: 1,
      },
      // 校验规则
      rules: {
        // 用户名
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        // 密码
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      qrBox: false,
      config: {},
      isLogin: false,
    };
  },
  created() {
    if (screen.width < 1000) {
      this.ruleForm.type = 1;

      // this.$router.push("/userLogin");
    } else {
      this.ruleForm.type = 2;
    }
    getadmininfo().then((res) => {
      ////console.log(res);
      if (res.code === 400200) {
        this.$router.push("/home");
      } else {
        return;
      }
    });
    // 判断全局配置是否存在
    if (!localStorage.getItem("config")) {
      getListConfig().then((res) => {
        if (res.code == 400200) {
          document.title = res.data.systematic_name;
          localStorage.setItem("config", JSON.stringify(res.data));
          this.config = res.data;
        }
      });
    }
    //
    if (this.$route.query.type == "out") {
      setTimeout(() => {
        this.socketApi.initWebSocket();
        this.$router.push("/login");
      }, 1000);
    }
  },
  methods: {
    // 登陆
    userLogin() {
      let { username, password, type } = this.ruleForm;
      let param = {
        userName: username,
        password,
        type,
      };
      if (username === "" && password === "") {
        this.$alert("请正确输入用户名和密码", "通知", {
          confirmButtonText: "确定",
        });
        return;
      } else {
        this.isLogin = true;
        login(param)
          .then((res) => {
            if (res.code === 400200) {
              this.isLogin = false;
              // 1. 成功提示
              this.$message({
                message: "恭喜你，登录成功",
                type: "success",
                showClose: true,
                duration: 1500,
              });
              localStorage.setItem("adminName", res.info.name);
              // 绑定socketId
              let websocketData = {
                socketid: localStorage.getItem("qyctSocketId"),
              };
              bind_websocket_id(websocketData);
              // 2. 跳转到home页
              localStorage.setItem("token", "admintoken");
              if (type === 1) {
                this.$router.push("/answer_content/answer_list");
              } else if (type === 2) {
                this.$router.push("/home");
              }
            } else {
              this.isLogin = false;
            }
          })
          .catch((err) => {
            this.isLogin = false;
          });
      }
    },
    // 判断手机
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    // 生成二维码
    qrcode() {
      console.log();
      // let url = window.location.protocol + "//" + window.location.host;
      // let url = JSON.parse(localStorage.getItem("config")).localhost;
      let url = this.$baseurl;
      let qrcode = new QRCode("qrcode", {
        width: 250, // 设置宽度，单位像素
        height: 250, // 设置高度，单位像素
        text: `${url}/index.html#/userLogin`, // 设置二维码内容或跳转地址
      });
    },
    // 显示二维码
    showQr() {
      ////console.log("123");
      this.qrBox = true;
      this.$nextTick(() => {
        this.qrcode();
      });
    },
    // 前往注册页面
    goRegistration() {
      this.$router.push(`/userRegistration`);
      // if (localStorage.getItem("config")) {
      //   let config = JSON.parse(localStorage.getItem("config"));
      //   console.log(config.user_register);
      //   if (config.user_register == 1) {
      //   } else {
      //     this.$alert("注册已关闭，请联系管理员", "通知", {
      //       confirmButtonText: "确定",
      //     });
      //   }
      // }
    },
  },
  mounted() {
    if (this._isMobile()) {
      // alert("手机端");
      this.$confirm("是否跳转到用户登陆界面", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.replace("/userLogin");
        })
        .catch(() => {});
    } else {
      // alert("pc端");
      // this.$router.replace("/pc_index");
    }
  },
};
</script>
<style lang="less">
.tc-login {
  overflow: hidden;
  background: url("../../assets/images/loginback-2.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  min-width: 1024px;
  min-height: 768px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // 登陆
  .login {
    padding-right: 100px;
    right: 200px;
    width: 350px;
    .el-button {
      width: 100%;
      height: 50px;
      border-radius: 5px;
      font-size: 30px;
      background: rgb(#007491);
    }
    .el-input {
      .el-input__inner {
        margin-top: 10px;
        height: 50px;
        font-size: 20px;
        padding-left: 50px;
        box-shadow: 2px 2px 10px rgb(156, 156, 156);
        &::placeholder {
          color: #007491;
        }
      }
      .el-input__icon {
        font-size: 30px;
        line-height: 65px;
        color: rgb(#007491);
      }
    }
    .title-cn {
      font-size: 50px;
      padding-bottom: 20px;
      font-weight: 700;
      text-align: center;
      color: rgb(#007491);
    }
    .title-eu {
      font-size: 30px;
      margin-bottom: 50px;
    }
    .twologin {
      display: flex;
      align-items: center;
      font-size: 20px;
      cursor: pointer;
      img {
        width: 25px;
      }
      span {
        padding-left: 10px;
      }
    }
    .wpass {
      // line-height: 3px;
      margin-left: 70px;
      font-size: 20px;
      color: rgb(#007491);
    }
    .zhuche {
      font-size: 20px;
      margin: 20px 0;
      color: rgb(72, 72, 72);
      a {
        text-decoration: none;
        transition: all 0.25s;
        &:hover {
          color: rgb(228, 60, 82);
          font-weight: 700;
          font-size: 20px;
        }
      }
    }
    .adminOrUser {
      margin-bottom: 20px;
      .el-radio__input.is-checked .el-radio__inner {
        background: #007491;
        border-color: #007491;
      }
      .el-radio__input.is-checked + .el-radio__label {
        color: #007491;
      }
    }
  }
  .qr {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .move {
    animation: moveleft 0.5s;
  }
  @keyframes moveleft {
    from {
      // transform: translateX(500px);
      transform: scale(0);
    }
  }
}
@media screen and (max-width: 450px) {
  .tc-login {
    background: transparent;
    background-image: linear-gradient(
      to right,
      rgb(191, 232, 248),
      rgb(45, 138, 192)
    );
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    min-width: 320px;
    max-width: 768px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    zoom: 0.7;
    // 登陆
    .login {
      padding-right: 100px;
      right: 200px;
      width: 350px;
      .el-button {
        width: 100%;
        height: 50px;
        border-radius: 5px;
        font-size: 30px;
        background: rgb(#007491);
      }
      .el-input {
        .el-input__inner {
          margin-top: 10px;
          height: 50px;
          font-size: 20px;
          padding-left: 50px;
          box-shadow: 2px 2px 10px rgb(156, 156, 156);
          &::placeholder {
            color: #007491;
          }
        }
        .el-input__icon {
          font-size: 30px;
          line-height: 65px;
          color: rgb(#007491);
        }
      }
      .title-cn {
        font-size: 50px;
        padding-bottom: 20px;
        font-weight: 700;
        text-align: center;
        color: rgb(#007491);
      }
      .title-eu {
        font-size: 30px;
        margin-bottom: 50px;
      }
      .twologin {
        display: flex;
        align-items: center;
        font-size: 20px;
        img {
          width: 25px;
        }
        span {
          padding-left: 10px;
        }
      }
      .wpass {
        // line-height: 3px;
        font-size: 20px;
        color: rgb(#007491);
      }
      .zhuche {
        font-size: 20px;
        margin: 20px 0;
        color: rgb(72, 72, 72);
        a {
          text-decoration: none;
          transition: all 0.25s;
          &:hover {
            color: rgb(228, 60, 82);
            font-weight: 700;
            font-size: 20px;
          }
        }
      }
      .adminOrUser {
        // display: none;
        margin-bottom: 20px;
        .el-radio__input.is-checked .el-radio__inner {
          background: #007491;
          border-color: #007491;
        }
        .el-radio__input.is-checked + .el-radio__label {
          color: #007491;
        }
      }
    }
  }
  .el-message-box__wrapper {
    .el-message-box {
      width: 60%;
    }
  }
}
</style>